$primary-color: #9eadbd;

@import 'animate.css';

@import '~loaders.css/src/animations/pacman.scss';

@font-face {
  font-family: 'Roboto';
  src: url('./assets/font/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Playfair';
  src: url('./assets/font/PlayfairDisplay-Regular.ttf') format('ttf');
}

input,
textarea {
  font-family: 'Roboto';
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}
