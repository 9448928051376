.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page,
.contact-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    h1 {
      font-size: 63px;
      font-family: 'Playfair';
      color: #022c43;
      font-weight: 300;
      margin-top: 0;
      position: relative;
      margin-bottom: 30px;
      left: 10px;
    }

    h2 {
      font-style: 20px;
      color: #9eadbd;
      font-family: 'Roboto';
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #9eadbd;
    }
  }
}
